<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="heading_alignment">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="careerCourseAdd">
              {{cvbtnAddNew}}
            </b-button>
          </template>
          <template >
            <b-row class="mb-3">
              <b-col class="col-12 col-sm-8 col-md-6 col-lg-7 mb-3">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col class="col-12 col-sm-4 col-md-4 col-lg-4 mb-3">
                <CsvDownload :propModuleFile="cvModuleFile" class="btn mr-1 Download_button"  />
                <CsvUpload  :propModulePrefix="cvModulePrefix" class="btn Download_button" />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" v-if="careerCourseObjList && careerCourseObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="careerCourseObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                  <template v-slot:cell(carr_course_name)="data">
                    <p v-html="data.item.carr_course_name"></p>
                    <b-button class="actionIcon btn mr-1 mb-1" size="sm" @click="careerCourseEdit('MODAL', data.item)" v-if="!data.item.editable">
                      <i class="font-size-20 ri-ball-pen-fill m-0"></i>
                    </b-button>
                    <b-button class="actionIcon btn" @click="showCareerCourseDeleteDialog(data.item)" size="sm">
                      <i class="font-size-20 ri-delete-bin-line m-0"></i>
                    </b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelCareerCourseAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl">
      <CareerCourseAdd :propOpenedInModal="true" @emitCloseCareerCourseAddModal="closeCareerCourseAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="careerCourseAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeCareerCourseAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelCareerCourseEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl">
      <CareerCourseEdit :propOpenedInModal="true" :propCareerCourseObj="careerCourseEditObj" @emitCloseCareerCourseEditModal="closeCareerCourseEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="careerCourseEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeCareerCourseEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelCareerCourseDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelCareerCourseDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="careerCourseDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<script>
import { CareerCourses } from "../../../FackApi/api/careerCourse.js"
import CareerCourseAdd from "./CareerCourseAdd"
import CareerCourseEdit from "./CareerCourseEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import CsvUpload from "../../../components/Csv/CsvUpload.vue"
import CsvDownload from "../../../components/Csv/CsvDownload.vue"

export default {
  name: "CareerCourseList",
  components: {
    CareerCourseAdd,
    CareerCourseEdit,
    CsvUpload,
    CsvDownload
  },
  data () {
    return {
      cvCardTitle: "Career Courses",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Career Course",
      cvAddModalHeader: "Add Career Course",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Career Course List Response",
      showModelCareerCourseAdd: false,
      showModelCareerCourseEdit: false,
      showModelCareerCourseDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },

        { label: "career", key: "carr_field", class: "text-left align-text-top", sortable: true },
        { label: "Career Course", key: "carr_course_name", class: "text-left align-text-top", sortable: true },
        { label: "Career Course Desc.", key: "carr_course_desc", class: "text-left align-text-top", sortable: true },
        { label: "Career Course Degree", key: "carr_course_degree", class: "text-left align-text-top", sortable: true },
        { label: "Career Course Degree type", key: "carr_course_degree_type", class: "text-left align-text-top", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left align-text-top", sortable: true }
      ],
      careerCourseObjList: null,
      careerCourseEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      delObj: null,
      cvModulePrefix: "carr_course",
      cvModuleFile: "career_course"
    }
  },
  async mounted () {
    await this.careerCourseList()
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * careerCourseList
     */
    async careerCourseList () {
      try {
        let careerCourseListResp = await CareerCourses.careerCourseList(this)
        if (careerCourseListResp.resp_status) {
          this.careerCourseObjList = careerCourseListResp.resp_data.data
          this.totalRows = careerCourseListResp.resp_data.count
        }
        else {
          this.toastMsg = careerCourseListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at careerCourseList() and Exception:", err.message)
      }
    },
    /**
     * careerCourseAdd
     */
    careerCourseAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/careerCourse_add")
        }
        else {
          this.showModelCareerCourseAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at careerCourseAdd() and Exception:", err.message)
      }
    },
    /**
     * careerCourseEdit
     */
    careerCourseEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/careerCourse_edit/" + this.careerCourseEditObj.carr_course_id)
        }
        else {
          this.careerCourseEditObj = item
          this.showModelCareerCourseEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at careerCourseEdit() and Exception:", err.message)
      }
    },
    /**
     * showCareerCourseDeleteDialog
     */
    showCareerCourseDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelCareerCourseDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showCareerCourseDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * careerCourseDelete
     */
    async careerCourseDelete () {
      try {
        let careerCourseDelResp = await CareerCourses.careerCourseDelete(this, this.delObj.carr_course_id)
        await ApiResponse.responseMessageDisplay(this, careerCourseDelResp)
        if (careerCourseDelResp && !careerCourseDelResp) {
          this.showModelCareerCourseDelete = false
          return false
        }
        let index = this.careerCourseObjList.indexOf(this.delObj)
        this.careerCourseObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelCareerCourseDelete = false
      }
      catch (err) {
        console.error("Exception occurred at careerCourseDelete() and Exception:", err.message)
      }
    },
    /**
     * closeCareerCourseAddModal
     */
    closeCareerCourseAddModal (careerCourseAddData) {
      try {
        if (careerCourseAddData) {
          if (this.careerCourseObjList && this.careerCourseObjList.length >= 1) {
            let carrcourseObjLength = this.careerCourseObjList.length
            let lastId = this.careerCourseObjList[carrcourseObjLength - 1]["id"]
            careerCourseAddData.id = lastId + 1
          }
          else {
            this.careerCourseObjList = []
            careerCourseAddData.id = 11111
          }
          careerCourseAddData.created_on = moment()
          this.careerCourseObjList.unshift(careerCourseAddData)
          this.totalRows = this.totalRows + 1
        }
        this.showModelCareerCourseAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closecareerCourseAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeCareerCourseEditModal
     */
    closeCareerCourseEditModal () {
      try {
        this.showModelCareerCourseEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeCareerCourseEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
