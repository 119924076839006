<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:body>
          <ValidationObserver ref="carrForm">
              <p v-show="!propOpenedInModal">
                {{cvCardSubHeader}}
              </p>
              <form action="#" v-if="vmCareerCourseFormData && Object.keys(vmCareerCourseFormData).length > 0">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_field">
                      {{cvCarrFieldLabel}}</label>
                      <ValidationProvider
                        name="Career Field Name"
                        rules="required"
                        v-slot="{ errors }">
                        <input
                          v-model="vmCareerCourseFormData.carr_field"
                          type="text"
                          class="form-control"
                          required
                          disabled/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_course_name">
                      {{cvCarrCourseNameLabel}}</label>
                      <ValidationProvider
                          name="Career Course Name"
                          rules="required"
                          v-slot="{ errors }">
                          <input
                          v-model="vmCareerCourseFormData.carr_course_name"
                          type="text"
                          class="form-control"
                          required/>
                        <span class="text-danger"> {{ errors[0] }} </span>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_course_desc">
                      {{cvCarrCourseDescLabel}}</label>
                      <ValidationProvider
                        name="Career Course Description"
                        rules="required"
                        v-slot="{ errors }">
                      <vue-editor v-model="vmCareerCourseFormData.carr_course_desc"></vue-editor>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_course_degree">
                      {{cvCarrCourseDegreeLabel}}</label>
                      <ValidationProvider
                          name="Career Course Degree "
                          rules="required"
                          v-slot="{ errors }">
                        <input
                        v-model="vmCareerCourseFormData.carr_course_degree"
                        type="text"
                        class="form-control"
                        required/>
                        <span class="text-danger"> {{ errors[0] }} </span>
                      </ValidationProvider>
                    </div>
                  <div class="col-md-12 mb-3">
                    <label
                      for="validationcarr_course_degree_type">
                      {{cvCarrCourseDegreeTypeLabel}}</label>
                      <ValidationProvider
                          name="Career Course Degree Type"
                          rules="required"
                          v-slot="{ errors }">
                        <input
                          v-model="vmCareerCourseFormData.carr_course_degree_type"
                          type="text"
                          class="form-control"
                          required/>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row spinner_add">
                  <button v-if="cvLoadingStatus" type="button" class="btn btn-primary float-right" :disabled="cvLoadingStatus">
                    <b-spinner label="Spinning">
                    </b-spinner>
                  </button>
                  <button type="button" class="btn btn-primary" @click="careerCourseEdit()">
                    {{cvSubmitBtn}}
                  </button>
                </div>
              </form>
          </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { CareerCourses } from "../../../FackApi/api/careerCourse"
import ApiResponse from "../../../Utils/apiResponse.js"
import { VueEditor } from "vue2-editor"
import { ValidationProvider } from "vee-validate"

export default {
  name: "CareerCourseEdit",
  components: {
    VueEditor,
    ValidationProvider
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propCareerCourseObj: {
      type: Object,
      default: function () {
        return {
          "carr_field": "",
          "carr_course_name": "",
          "carr_course_desc": "",
          "carr_course_degree": "",
          "carr_course_degree_type": ""
        }
      }
    }
  },
  data () {
    return {
      cvSelectBoxText: "Select ",
      cvCardTitle: "Edit career Course",
      cvCardSubHeader: "Edit Career Course ",
      cvSubmitBtn: "Edit",
      cvCarrFieldLabel: "career",
      cvCarrCourseNameLabel: "Career Course",
      cvCarrCourseDescLabel: "Career Course Desc.",
      cvCarrCourseDegreeLabel: "Career Course Degree",
      cvCarrCourseDegreeTypeLabel: "Career Course Degree Type",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Career Course",
      vmCareerCourseFormData: {}
    }
  },
  async mounted () {
    await this.careerCourseView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmCareerCourseFormData) {
          if (!this.vmCareerCourseFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * careerCourseView
     */
    async careerCourseView () {
      try {
        if (this.propOpenedInModal) {
          this.vmCareerCourseFormData = this.propCareerCourseObj
        }
        else {
          let careerCourseId = this.$route.params.carr_course_id
          let careerCourseViewResp = await CareerCourses.careerCourseView(this, careerCourseId)
          if (careerCourseViewResp && careerCourseViewResp.resp_status) {
            this.vmCareerCourseFormData = careerCourseViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at careerCourseView() and Exception:", err.message)
      }
    },
    /**
     * careerCourseEdit
     */
    async careerCourseEdit () {
      try {
        let valid = await this.$refs.carrForm.validate()
        if (!valid) {
          return
        }
        this.cvLoadingStatus = true
        let careerCourseAddResp = await CareerCourses.careerCourseEdit(this, this.vmCareerCourseFormData)
        await ApiResponse.responseMessageDisplay(this, careerCourseAddResp)
        if (careerCourseAddResp && !careerCourseAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseCareerCourseEditModal", this.vmCareerCourseFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at careerCourseEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
